import React from 'react';
import {ActionColumn, CodeInput, CodeColumn, MenuAction, ActionBtn, CustomInfoButton, MaxTicketsLabel} from './styles';
import {Dropdown, Menu} from 'antd';
import {ButtonMore, ButtonTypes} from '../../../ui-kit/Button';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {ModalLvl} from './Table';
import {InputNumber} from '../../../ui-kit/Input';
import {ViewTicketMap} from '../../../helpers/table';
import {TicketSendStatuses} from '../../../types/tickets';
import {guestStateVal, ticketState, ticketStateVal} from '../../../hooks/groups';
import {guestState} from '../../../queries/types/groups';
import {sendMultipleT} from '../../../hooks/emails';
import {MAX_TICKETS} from '../../../helpers/common';
import {CloseCircleOutlined} from '@ant-design/icons';

type MenuMoreProps = {
  isEditing: boolean;
  id?: string;
  focusedId?: string;
  setDelPopup?: (lvl: ModalLvl) => void;
  setFocusId?: (id?: string) => void;
  setEditing?: (b: boolean) => void;
};

export const MenuMore: React.FC<MenuMoreProps> = ({id, setDelPopup, setEditing, setFocusId}) => {
  const onEdit = () => {
    setFocusId?.(id);
    setEditing?.(true);
    setDelPopup?.(ModalLvl.closed);
  };
  const onDelete = () => {
    setFocusId?.(id);
    setEditing?.(false);
    setDelPopup?.(ModalLvl.confirm);
  };

  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
    {
      key: 2,
      label: (
        <MenuAction $danger onClick={onDelete}>
          Delete
        </MenuAction>
      ),
    },
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};

export type ItemProps = {
  addRow?: boolean;
  edit?: boolean;
  ticket?: string;
  itemContent?: string;
  nameState?: ticketState;
  emailState?: ticketState;
  placeholder?: string;
  type?: string;
  no?: string;
  id?: string;
  isEditing?: boolean;
  focusId?: string;
  setName?: (val: ticketStateVal) => void;
  setEmail?: (val: ticketStateVal) => void;
  setFocusId?: (id?: string) => void;
  count?: number;
  email?: string;
  name?: string;
  guestSetState?: (val: guestStateVal) => void;
  guestState?: guestState;
  ticketType?: string;
  max?: number;
  editingId?: string;
  onSave?: (id?: string) => void;
  setEditingId?: (id?: string) => void;
  eGuestSetState?: (val: guestStateVal) => void;
  eGuestState?: guestState;
};

export const NumberItem: React.FC<ItemProps> = ({no}) => {
  return (
    <CodeColumn>
      <div>{no}</div>
    </CodeColumn>
  );
};

export const InputEmailItem: React.FC<ItemProps> = ({
  id,
  setFocusId,
  emailState,
  setEmail,
  addRow,
  guestSetState,
  guestState,
  editingId,
  eGuestSetState,
  eGuestState,
  ...props
}) => {
  const onFocus = () => setFocusId?.(id);
  const onChange = (e: any) => setEmail?.({key: String(id), email: e?.target?.value});
  const guestChange = (e: any) => guestSetState?.({key: 'email', value: e?.target?.value});
  const eGuestChange = (e: any) => eGuestSetState?.({key: 'email', value: e?.target?.value});
  const isCurrentEditing = id && editingId === id;

  if (addRow)
    return (
      <CodeInput
        placeholder="Type email..."
        value={guestState?.email}
        onChange={guestChange}
        onFocus={onFocus}
        type={'email'}
      />
    );

  if (isCurrentEditing)
    return <CodeInput placeholder="Type email..." value={eGuestState?.email} onChange={eGuestChange} type={'email'} />;
  if (!id) return null;
  return (
    <CodeColumn>
      {props?.email ? (
        <div>{props.email}</div>
      ) : (
        <CodeInput
          placeholder="Type email..."
          value={emailState?.[id]?.email || ''}
          onChange={onChange}
          onFocus={onFocus}
          type={'email'}
        />
      )}
    </CodeColumn>
  );
};

export const InputNameItem: React.FC<ItemProps> = ({
  id,
  setFocusId,
  nameState,
  setName,
  addRow,
  guestState,
  guestSetState,
  eGuestState,
  editingId,
  eGuestSetState,
  ...props
}) => {
  const onFocus = () => setFocusId?.(id);
  const onChange = (e: any) => setName?.({key: String(id), name: e?.target?.value});
  const guestChange = (e: any) => guestSetState?.({key: 'name', value: e?.target?.value});
  const eGuestChange = (e: any) => eGuestSetState?.({key: 'name', value: e?.target?.value});
  const isCurrentEditing = id && editingId === id;

  const disabled = !!props?.email;
  if (addRow)
    return (
      <CodeInput
        placeholder="Type name..."
        value={guestState?.name}
        onChange={guestChange}
        onFocus={onFocus}
        type={'name'}
      />
    );

  if (isCurrentEditing)
    return <CodeInput placeholder="Type name..." value={eGuestState?.name} onChange={eGuestChange} type={'name'} />;

  if (!id) return null;
  return (
    <CodeColumn>
      {props?.name ? (
        <div>{props.name}</div>
      ) : (
        <CodeInput
          placeholder="Type name..."
          value={nameState?.[id]?.name || ''}
          onChange={onChange}
          onFocus={onFocus}
          type={'name'}
          disabled={disabled}
        />
      )}
    </CodeColumn>
  );
};

export const AdmissionItem: React.FC<ItemProps> = ({
  count,
  addRow,
  eGuestSetState,
  eGuestState,
  guestSetState: setState,
  guestState: state,
  ticketType,
  editingId,
  id,
  max,
}) => {
  const isCurrentEditing = id && editingId === id;
  const guestState = isCurrentEditing ? eGuestState : state;
  const guestSetState = isCurrentEditing ? eGuestSetState : setState;

  const guestTicketVal = ticketType ? Number(guestState?.[ticketType]) || 0 : 0;
  const onDecrease =
    (addRow || isCurrentEditing) && ticketType
      ? () => guestSetState?.({key: ticketType, value: guestState?.[ticketType] ? guestTicketVal - 1 : 0})
      : undefined;
  const onIncrease =
    (addRow || isCurrentEditing) && ticketType
      ? () => guestSetState?.({key: ticketType, value: guestTicketVal + 1})
      : undefined;
  const onChange = addRow && ticketType ? (value: any) => guestSetState?.({key: ticketType, value: value}) : undefined;
  const value = addRow || isCurrentEditing ? (ticketType ? guestTicketVal : 0) : count;
  const isDisabled = (addRow && !guestState?.validInfo) || (!addRow && !guestState?.validInfo);
  const max_tickets_count = (guestState?.number_tickets || 0) > MAX_TICKETS || (value || 0) >= (max || 0);

  return (
    <CodeColumn>
      <InputNumber
        min={0}
        addonBefore={
          <button onClick={onDecrease} disabled={isDisabled}>
            -
          </button>
        }
        addonAfter={
          <button onClick={onIncrease} disabled={isDisabled || max_tickets_count}>
            +
          </button>
        }
        controls={false}
        value={value}
        max={max}
        onChange={onChange}
        disabled={isDisabled ? !isCurrentEditing : false}
      />
    </CodeColumn>
  );
};

export type ActionsItemProps = MenuMoreProps & {
  addRow?: boolean;
  edit?: boolean;
  id?: string;
  email?: string;
  setFocusId?: (id?: string) => void;
  // ticketStatus?: 'sent' | 'applied';
  ticketStatus?: 'sent' | 'queued' | 'notsent';
  onSendTicket?: (id?: string) => void;
  loading?: boolean;
  emailState?: ticketState;
  guestState?: guestState;
  onAddGuest?: () => Promise<boolean>;
  sendMultiple?: sendMultipleT;
  editingId?: string;
  onSave?: (id?: string) => void;
  setEditingId?: (id?: string) => void;
  eGuestState?: guestState;
  eGuestSetState?: (val: guestStateVal) => void;
  onDelete?: (id?: string) => void;
};

export const ActionsItem: React.FC<ActionsItemProps> = ({
  ticketStatus,
  setFocusId,
  id,
  loading,
  addRow,
  onAddGuest,
  onSendTicket,
  guestState,
  editingId,
  setEditingId,
  onSave,
  eGuestState,
  onDelete,
}) => {
  const onSend = () => {
    setFocusId?.(id);
    onSendTicket?.(id);
  };
  const isSent = ticketStatus === TicketSendStatuses.queued || ticketStatus === TicketSendStatuses.sent;
  const onSetEdit = () => setEditingId?.(id);
  const onCancelEdit = () => setEditingId?.('');
  const handleSave = () => onSave?.(id);
  const handleDelete = () => onDelete?.(id);
  const sendText = isSent ? 'Resend' : 'Send';
  const toadd_tickets_count = (guestState?.number_tickets || 0) > MAX_TICKETS;
  const toupdate_tickets_count = (eGuestState?.number_tickets || 0) > MAX_TICKETS;

  if (addRow)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onAddGuest} disabled={loading || !guestState?.valid}>
          Save
        </ActionBtn>
        {toadd_tickets_count && <MaxTickets />}
      </ActionColumn>
    );

  if (id === editingId)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onCancelEdit} disabled={loading}>
          Cancel
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={handleSave} disabled={loading || !eGuestState?.valid}>
          Update
        </ActionBtn>
        {toupdate_tickets_count && <MaxTickets />}
      </ActionColumn>
    );

  if (isSent)
    return (
      <ActionColumn>
        <CustomInfoButton variant={ViewTicketMap?.sent}>Ticket Sent</CustomInfoButton>
        <MenuMoreTicketGroup isEditing={false} onEdit={onSetEdit} onSend={onSend} sendText={sendText} />
      </ActionColumn>
    );

  return (
    <ActionColumn>
      <ActionBtn variant={ButtonTypes.secondary} onClick={onSetEdit}>
        Edit
      </ActionBtn>
      <ActionBtn variant={ButtonTypes.secondary} onClick={onSend} disabled={loading}>
        Send
      </ActionBtn>
      <ActionBtn variant={ButtonTypes.secondary} onClick={handleDelete} disabled={loading}>
        Delete
      </ActionBtn>
    </ActionColumn>
  );
};

const MaxTickets = () => (
  <MaxTicketsLabel $valid={false} icon={<CloseCircleOutlined />}>
    No more than 15 tickets per email
  </MaxTicketsLabel>
);

export type MenuMoreTicketGroupProps = {
  isEditing: boolean;
  id?: string;
  onEdit?: () => void;
  onSend?: () => void;
  sendText: string;
};

export const MenuMoreTicketGroup: React.FC<MenuMoreTicketGroupProps> = ({onEdit, onSend, sendText}) => {
  const ddActions: ItemType[] = [
    {key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>},
    {
      key: 2,
      label: <MenuAction onClick={onSend}>{sendText}</MenuAction>,
    },
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};
